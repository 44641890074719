export const environment = {
  production: false,
  authServerHost: 'https://api.electromagnetism.dev.gametailors.com/auth/',
  casHost: 'https://api.electromagnetism.dev.gametailors.com/cas',
  // TODO: Do not hardcode this
  dataPackage: 'dev_datapackage-824b700b-2198-4b9f-8e7b-b6d2a7acf605',
  defaultGame: 'electro',
  aspectRatio: { width: 1920, height: 1080 },
  playGameUrl: 'https://play.electromagnetism.dev.gametailors.com/',

  version_latestBuildNumber: '1159',
  version_latestCommitHash: 'e87b7e293bce38637dc22d817bd057ddcef5060f',
  version_latestDate: '13/12/2024 12:14',
};
